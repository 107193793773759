import React, { Component } from "react"

import Layout from "../components/layout"

import * as styles from '../styles/pages/calculator.module.css'

import PaintHeader from "../components/paintcalculator/header"
import PaintTable from "../components/paintcalculator/painttable"
import ControlSelect from "../components/controls/select"


import calculatordata from "../content/calculatordata.json"
import substratelabels from "../content/substratelabels.json"


import surfacetypelist from "../content/substrateradiolist.json"
import surfacetypesublist from "../content/substrateradiosublist.json"
import substratelist from "../content/substrate.json"

import surfacetypesublabel from "../content/substrateradiosublabel.json"


class CalculatorPage extends Component {
	state = {
		calcdata: null,
		surfacelist: [],
		surfacesubtype: [],
		maintype: "",
		conditiontext: "",
		surfacesubtext:"",
		surfacetext:"",
		surfacecode:""
	};

	handleSurfacetypeChange = (newvalue) => {
		if (this.state.maintype === newvalue) {
			return;
		}
		this.handleSurfaceSubtypeChange(surfacetypesublist[newvalue][0].code);
		var tmptypetext = "";
		var i = 0;
		if (newvalue !== "") {
			while (i < surfacetypelist.length) {
				if (newvalue === surfacetypelist[i].code) {
					tmptypetext = surfacetypelist[i].name;
				}
				i = i + 1;
			}
		}
		this.setState({
			surfacesubtype: surfacetypesublist[newvalue],
			conditiontext: tmptypetext,
			maintype: newvalue
		});
	}

	handleSurfaceSubtypeChange = (newvalue) => {
		console.log("handleSurfaceSubtypeChange", newvalue);
		//console.log("handleSurfaceSubtypeChange", surfacetypesublabel[newvalue]);
		var tmpsurfacelist = [];
		var tmpsubstratelist = substratelist[newvalue];
		var i = 0;
		var newkey = "";
		var tmpcalcdata = null;
		while (i < tmpsubstratelist.length) {
			tmpsurfacelist.push({
					"code":tmpsubstratelist[i],
					"name":this.getSurfaceName(tmpsubstratelist[i])
				});
			i = i + 1;
		}
		if (tmpsurfacelist.length>0) {
			newkey = tmpsurfacelist[0].code;
			tmpcalcdata = calculatordata[tmpsurfacelist[0].code];
		}
		this.setState({
			surfacesubtext: surfacetypesublabel[newvalue],
			surfacelist: tmpsurfacelist,
			surfacetext: this.getSurfaceName(newkey),
			surfacecode: newkey,
			calcdata: tmpcalcdata
		});

	}
	handleDetailChange = (newvalue) => {
		this.setState({
			surfacetext: this.getSurfaceName(newvalue),
			surfacecode: newvalue,
			calcdata: calculatordata[newvalue]
		});
	}
	getSurfaceName = (newvalue) => {
		var newtext = substratelabels[newvalue];
		if ("dapn" === newvalue) {
			newtext = "Gypsum Board";
		}
		return newtext;
	}

	render() {
		return (
			<Layout activePath='calculator' pageTitle="Product Calculator">
				<PaintHeader
					surface={this.state.conditiontext}
					typeinfo={this.state.surfacesubtext}
					condition={this.state.surfacetext} />
				<div className={"text-centered "+styles.surfaceselect}>
					<ControlSelect
						id="surfacetype"
						handleChange={this.handleSurfacetypeChange}
						list={surfacetypelist}
					/>
				{
					(this.state.surfacesubtype.length > 1) &&
						<>
							<br/>
							<ControlSelect
									id="detail"
									handleChange={this.handleSurfaceSubtypeChange}
									list={this.state.surfacesubtype}
								/>
						</>
				}
				{
					(this.state.surfacelist.length > 0) &&
						<>
							<br/>
							<ControlSelect
									id="detail"
									handleChange={this.handleDetailChange}
									list={this.state.surfacelist}
								/>
						</>
				}
				</div>
				{
					this.state.calcdata &&
					<PaintTable
							surfacecalc={this.state.calcdata}
							surfacetext={this.state.surfacetext}
						/>
				}
				<br/>
				<br/>
			</Layout>
		);
	}
}

export default CalculatorPage
